import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from 'react-modal';
import './popup.css';
import {FaWindowClose} from 'react-icons/fa'

const Cardcarousel = ({ items }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    centerPadding: '60px',
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div className="tp-project-4-item" key={index}>
            <div className="officecarde" onClick={() => openModal(item.imageUrl)}>
              <div className="tp-project-4-item-thumb tp-thumb-common">
                <div className="tp-thumb-common-overlay-red wow"></div>
                <img src={item.imageUrl} alt={item.title} loading="lazy" />
              </div>
              <div className="dgi-gallery-content">
                <h4 className="">{item.title}</h4>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
      >
        <div className="imge-body">
          <img src={selectedImage} alt="Selected" />
          <button className="close-button" onClick={closeModal}><FaWindowClose/></button>
        </div>
      </Modal>
    </div>
  );
};

export default Cardcarousel;
