import React from "react";
import Faq from "../../../../components/Faq/faq";
import Banner from "../../../../components/Bannersection/banner";
import { Helmet } from "react-helmet";
import {
  BsEmojiLaughing,
  BsBank 
} from "react-icons/bs";

import { FaLaptop ,FaRobot ,FaRocketchat,FaBookOpen ,FaAward,FaRegUserCircle ,FaStore,FaClinicMedical,FaParachuteBox,FaHandHoldingUsd,FaHotel  } from "react-icons/fa";
import { GiGiftOfKnowledge } from "react-icons/gi";
import { FaCarTunnel ,FaHandshakeSimple  } from "react-icons/fa6";
import { BiSlideshow } from "react-icons/bi";
import { GoLaw } from "react-icons/go";
import { TbBrandSpeedtest ,TbBuildingFactory2 ,TbShoppingCartFilled  } from "react-icons/tb";
import { AiFillSetting , } from "react-icons/ai";
import Boxcard from "../../../../components/Cardesection/boxcard";
import Clientlogos from "../../../../components/Clientlogo/clientlogos";
import Rightlogo from "../../../../components/Clientlogo/rightlogo";

import aimodel from '../../../../components/Clientlogo/aimodel.json';
import aimodel2 from '../../../../components/Clientlogo/aimodel2.json';
function ArtificialIntelligence() {
  const pagename = [
    {
      pagetitle: "Artificial Intelligence",
      subtitle: "Artificial Intelligence",
    },
  ];
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "Why should I integrate AI into my business?",
      answer:
        "Incorporating AI into your business offers numerous benefits. AI solutions enhance efficiency, improve decision-making, and foster innovation, resulting in better outcomes. By analyzing large datasets, AI provides valuable insights for strategic decision-making. Automation of repetitive tasks streamlines processes, reducing errors and freeing up time for high-value tasks. Additionally, AI enables personalized customer experiences, boosting engagement and satisfaction. In today’s competitive landscape, AI helps you stay agile and seize opportunities in real time, gaining a competitive edge. Embracing AI positions your business at the forefront of technology, unlocking growth, efficiency, and innovation potential.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How do AI models work?",
      answer:
        "AI models process vast data using algorithms to learn patterns. They adjust parameters during training to minimize errors. These models have specific architectures for tasks like image recognition or language processing. After training, they're tested on new data for accuracy. Once validated, they're deployed for predictions or decisions in real-world scenarios. They can improve over time with feedback loops and exposure to new data.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "What are the types of AI solutions you build?",
      answer:
        "We excel in AI development services, offering diverse solutions tailored to various industries. Our expertise includes natural language processing, recommendation systems, and predictive analytics. From intelligent chatbots to image recognition and personalized recommendation engines, we craft solutions aligned with your objectives. Leveraging machine learning and deep learning, our AI experts drive innovation, efficiency, and competitive advantage in today's dynamic tech landscape.",
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question:
        "What does your tech stack for AI application development entail?",
      answer:
        "We employ a comprehensive toolkit of cutting-edge technologies to craft powerful AI solutions. Our arsenal includes industry-standard platforms, libraries, and frameworks. For machine learning, we rely on TensorFlow and PyTorch, offering versatility in model building. To process text data, we utilize NLTK and spaCy for natural language processing. Cloud platforms like AWS, Azure, and Google Cloud provide scalable resources for model training and deployment.  Our expertise extends to deep learning frameworks like Keras and scikit-learn for constructing predictive models and recommendation systems. We tailor our approach to each project, selecting the optimal tools to deliver innovative, efficient AI solutions aligned with your business objectives.",
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question:
        "How much time is required to lay out the project scope, devise a plan, and execute an AI project?",
      answer:
        "The timeline for an AI project varies based on its complexity and requirements. Factors like data availability, algorithm complexity, custom model architectures, and integration affect the duration. Iterative development and testing may be needed for optimization. Tailoring the timeline ensures alignment with project goals and needs.",
    },
    {
      Faq_id: "collapsesix",
      data_bs_target: "#collapsesix",
      question:
        "How do you ensure the security of an AI project?",
      answer:
        "For AI project security, we employ robust measures including data encryption, access controls, and secure architecture. We prioritize user authentication, conduct regular security audits, and use advanced intrusion detection systems for real-time threat detection. Following industry best practices, we update software components and provide continuous employee training to stay ahead of security challenges. Adhering to data protection regulations and compliance standards ensures the confidentiality, integrity, and availability of our AI solutions.",
    },
  ];

  const aiservice = [
    {
      icons: BsBank ,
      title: "Banking and Finance",
      description:
        "Utilize AI to revamp financial apps, automate analysis, streamline budgeting, and improve decision-making through predictive analysis.",
    },
    {
      icons: FaStore ,
      title: "Retail",
      description:
        "Analyze customer behavior, improve inventory management, reduce churn, and enable recommendations with AI-based applications.",
    },
    {
      icons: FaClinicMedical ,
      title: "Healthcare",
      description:
        "Utilize AI Analytics for efficient diagnosis, data-driven recommendations, predictions, and personalized life-saving treatment.",
    },
    {
      icons: FaParachuteBox ,
      title: "Supply Chain and Logistics",
      description:
        "Streamline receipt and invoice processing, automate data tasks, and elevate customer experience with AI capabilities.",
    },
    {
      icons: FaHandHoldingUsd ,
      title: "Insurance",
      description:
        "Automate claims processing, enhance user interactions with AI chatbots, detect and address risks, and prioritize customer service.",
    },
    {
      icons: TbBuildingFactory2 ,
      title: "Manufacturing",
      description:
        "Utilize AI for efficient quality control, predictive maintenance, and optimized supply chain management, ensuring operational excellence in manufacturing.",
    },
    {
      icons: FaCarTunnel ,
      title: "Automotive",
      description:
        "Boost automotive business with AI solutions: design, safety, route optimization, predictive maintenance, minimizing downtime.",
    },
    {
      icons: FaHotel ,
      title: "Hospitality",
      description:
        "We develop robust solutions for hospitality, personalizing guest experiences, optimizing operations, and enhancing marketing strategies.",
    },

    {
      icons: FaHandshakeSimple ,
      title: "Real Estate",
      description:
        "Our AI solutions in real estate optimize property pricing, improve customer interactions, and inform strategic decisions for real estate businesses.",
    },
    {
        icons: BiSlideshow ,
        title: "Media & Entertainment",
        description:
          "Innovate content, personalize experiences, optimize distribution with AI tools, recommendation systems, and more.",
      },
    {
    icons: TbShoppingCartFilled ,
    title: "E-commerce",
    description:
        "Improve customer service, streamline inventory, boost sales, and increase efficiency with AI-driven personalization and predictive analytics.",
    },
    {
    icons: GoLaw ,
    title: "Legal",
    description:
        "AI solutions aid legal firms in compliance, risk management, legal research, case management, workflow optimization, and client engagement.",
    },
  ];
  const company = {
    name: "Automation",
    url: "https://dgiworx.com.au/automation",
    logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "AU",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };
  return (
    <div>
      <Helmet>
        <title>Artificial Intelligence | DGi WORX</title>
        <meta
          name="description"
          content="Discover DGi WORX's Artificial Intelligence in Australia, specializing in cloud automation, network automation, and security automation. Elevate your business processes with cutting-edge automation solutions tailored for the Australian market."
        />
        <meta
          name="keywords"
          content="Artificial Intelligence, 
    automated network management tools, automated social networking,
    cloud automation, automatic cloud backup pc,
    cloud based lease automation software, network automation, 
    security automation, DGi WORX automation, Australian automation solutions,
    business process automation, IT automation, robotic process automation,
    technology automation, automation tools, Australia Artificial Intelligence"
        />
        <meta name="geo.country" content="Australia" />
        <meta name="geo.placename" content="Piara Waters, Western Australia" />
        <meta property="og:locale" content="en_au" />
        <meta
          property="og:title"
          content="Artificial Intelligence | DGi WORX"
        />
        <meta
          property="og:description"
          content="Discover DGi WORX's Artificial Intelligence in Australia, specializing in cloud automation, network automation, and security automation. Elevate your business processes with cutting-edge automation solutions tailored for the Australian market."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta
          property="og:url"
          content="https://dgiworx.com.au/artificialintelligence"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/service/artificial-intelligence/ai.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/artificial-intelligence/ai.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                    Unlocking the Future of Intelligent Solutions
                  </span>
                  <h2 className="tp-section-title">AI Business Solutions</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      Enhance your business capabilities with our specialized AI
                      development services, crafted to optimize your business
                      processes and boost productivity. Our offerings range from
                      expert AI consultancy to meticulous data annotation and
                      the creation of bespoke AI applications that integrate
                      effortlessly into your existing systems. Additionally, we
                      specialize in refining foundational models such as GPT,
                      Llama, PaLM, Mistral, and Gemini, developing tailored
                      solutions that align precisely with your business's
                      specific needs.
                    </p>
                    <p>
                      Enhance your business workflows and operational efficiency
                      with our specialized AI development services. From initial
                      consulting to the deployment of custom AI applications, we
                      provide a full spectrum of solutions to integrate
                      cutting-edge AI into your business processes.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      AI Consulting Expertise
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Custom AI Development
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Data Labeling Solutions
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Foundation Model Tuning
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Industry-Specific Customization
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Operational Efficiency Boost
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>
      {/* <!-- Our Business Ethos --> */}
      <section className="feature-area py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="tp-section tp-section-white text-center mb-100">
                <span className="tp-section-sub-title">
                  Unlocking Potential, Enabling Success
                </span>
                <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">
                  Robust AI Service And Solution
                </h2>
                <p className="col-lg-10 col-sm-12 text-dark mx-auto">
                  At DGi WORX, a leading provider of artificial intelligence
                  solutions, our top-performing team specializes in crafting
                  tailored AI software development solutions for various
                  industries.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-50">
                <div
                  className="tp-feature-4-thumb"
                  data-background="assets/img/feature/four/feature-4-thumb-1.jpg"
                ></div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i>
                      <FaLaptop  className="flaticon-product-design" />
                    </i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">
                      MACHINE LEARNING
                    </h4>
                    <p>
                      We provide your business with the remarkable benefits of
                      AI, empowering machines to analyze data and take actions
                      akin to humans.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-50">
                <div
                  className="tp-feature-4-thumb"
                  data-background="assets/img/feature/four/feature-4-thumb-1.jpg"
                ></div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i>
                      <FaRobot  className="flaticon-solution" />
                    </i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">
                      ROBOTIC AUTOMATION
                    </h4>
                    <p>
                      Our AI technology boasts immense potential, elevating
                      robotic process automation to new heights by enhancing
                      work efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-50">
                <div
                  className="tp-feature-4-thumb"
                  data-background="assets/img/feature/four/feature-4-thumb-1.jpg"
                ></div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i>
                      <AiFillSetting  className="flaticon-technology" />
                    </i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">AI MANAGEMENT</h4>
                    <p>
                      We develop AI software for automating business decisions,
                      leveraging algorithms and predictive analysis. Utilize
                      data-driven insights for intelligent optimization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-50">
                <div
                  className="tp-feature-4-thumb"
                  data-background="assets/img/feature/four/feature-4-thumb-1.jpg"
                ></div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i>
                      <FaBookOpen  className="flaticon-product-design" />
                    </i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">DEEP LEARNING</h4>
                    <p>
                      Our experts specialize in Deep Learning to explore complex
                      data thoroughly, unlocking vast opportunities for your
                      business and providing optimal solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-50">
                <div
                  className="tp-feature-4-thumb"
                  data-background="assets/img/feature/four/feature-4-thumb-1.jpg"
                ></div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i>
                      <FaRocketchat  className="flaticon-solution" />
                    </i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">CHATBOT</h4>
                    <p>
                      Our design creates a virtual agent AI system that
                      comprehends and translates human behavior, enhancing the
                      human experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-50">
                <div
                  className="tp-feature-4-thumb"
                  data-background="assets/img/feature/four/feature-4-thumb-1.jpg"
                ></div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i>
                      <GiGiftOfKnowledge className="flaticon-technology" />
                    </i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">
                      VIRTUAL KNOWLEDGE
                    </h4>
                    <p>
                      Our AI development company constructs robust virtual
                      knowledge systems to facilitate informed decision-making
                      using virtual databases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our Business Ethos-end --> */}

      {/* <!-- step-area-start --> */}
      <section className="step-area step-area-back py-50">
        <div className="container">
          <div className="tp-section text-center mt-10 mb-50">
            {/* <span className="tp-section-sub-title">Recently completed work</span> */}
            <h4 className="tp-section-title">Why Choose DGi WORX?</h4>
            {/* <p className="text-center">
              we pride ourselves on our meticulously crafted workflow that
              ensures efficiency, transparency, and top-notch results.
            </p> */}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-step-wrapper d-flex justify-content-between">
                <div
                  className="tp-step-item text-center mb-30 wow bounceIn"
                  data-wow-duration=".6s"
                  data-wow-delay=".6s"
                >
                  <div className="tp-step-item-count">
                    <span>1</span>
                  </div>
                  <div className="tp-step-item-overlay p-relative mb-30">
                    <div className="tp-step-item-thumb">
                      <img
                        src="assets/img/artificial_intelligence/why_choose-1.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="tp-step-item-thumb-icon">
                      <i>
                        <FaAward  className="flaticon-design" />
                      </i>
                    </div>
                  </div>
                  <div className="tp-step-item-content">
                    <h4 className="tp-step-item-title">Expert AI Professionals</h4>
                    <p>
                    DGi WORX experts in ML, NLP, and<br/> computer vision deliver proven, tailored AI solutions across diverse domains.
                    </p>
                  </div>
                </div>
                <div
                  className="tp-step-item tp-step-item-even text-center mb-30 wow bounceIn"
                  data-wow-duration=".5s"
                  data-wow-delay=".5s"
                >
                  <div className="tp-step-item-count">
                    <span>2</span>
                  </div>
                  <div className="tp-step-item-overlay p-relative mb-30">
                    <div className="tp-step-item-thumb">
                      <img
                        src="assets/img/artificial_intelligence/why_choose-2.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="tp-step-item-thumb-icon">
                      <i>
                        <FaRegUserCircle  className="flaticon-design" />
                      </i>
                    </div>
                  </div>
                  <div className="tp-step-item-content">
                    <h4 className="tp-step-item-title">Extensive Experience in AI</h4>
                    <p>
                        Trusted AI partner, delivering diverse, <br/>successful recommendation systems and chatbots with deep expertise.
                    </p>
                  </div>
                </div>
                <div
                  className="tp-step-item text-center mb-30 wow bounceIn"
                  data-wow-duration=".5s"
                  data-wow-delay=".5s"
                >
                  <div className="tp-step-item-count">
                    <span>3</span>
                  </div>
                  <div className="tp-step-item-overlay p-relative mb-30">
                    <div className="tp-step-item-thumb">
                      <img
                        src="assets/img/artificial_intelligence/why_choose-3.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="tp-step-item-thumb-icon">
                      <i>
                        <TbBrandSpeedtest  className="flaticon-design" />
                      </i>
                    </div>
                  </div>
                  <div className="tp-step-item-content">
                    <h4 className="tp-step-item-title">We Prioritize Speed</h4>
                    <p>
                    Our team ensures swift, top-quality <br/>AI project delivery with streamlined processes and advanced tech.
                    </p>
                  </div>
                </div>
                <div
                  className="tp-step-item tp-step-item-even text-center mb-30 wow bounceIn"
                  data-wow-duration=".5s"
                  data-wow-delay=".6s"
                >
                  <div className="tp-step-item-count">
                    <span>4</span>
                  </div>
                  <div className="tp-step-item-overlay p-relative mb-30">
                    <div className="tp-step-item-thumb">
                      <img
                        src="assets/img/artificial_intelligence/why_choose-4.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="tp-step-item-thumb-icon">
                      <i>
                        <BsEmojiLaughing className="flaticon-design" />
                      </i>
                    </div>
                  </div>
                  <div className="tp-step-item-content">
                    <h4 className="tp-step-item-title">End-to-end AI Development</h4>
                    <p>
                    We consult, tailor AI, rigorously test,<br/> refine,  and seamlessly integrate for maximum operational benefits.
                    </p>
                  </div>
                </div>
                <div className="tp-step-line-shape d-none d-xl-block">
                  <img
                    src="assets/img/thumbs/step/step-line-shape-1.svg"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- step-area-end --> */}

      {/* service section */}
      <section className="feature-area py-50 p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">
                    Unlocking Potential, Driving Growth
                </span>
                <h2 className="tp-section-title">Elevate Your Industry with Our AI Solutions</h2>
                <p>
                Elevate your industry with our cutting-edge AI solutions. Our tailored development services are designed to propel your business to the next level, leveraging the power of artificial intelligence to optimize operations, enhance decision-making, and drive growth.
                </p>
              </div>
            </div>
          </div>
          <Boxcard item={aiservice} />
        </div>
        <div className="tp-feature-shape">
          
          <div className="tp-feature-shape-one w-img">
            <img
              src="assets/img/feature/one/features-bg.png"
              alt="Elevating Possibilities"
              loading="lazy"
            />
          </div>
        </div>
      </section>

      <section className="brand-area py-50">
        <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="tp-section tp-section-white text-center mb-50">
                <span className="tp-section-sub-title">
                Specialized Proficiency
                </span>
                <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">
                Our Expertise in AI Models
                </h2>
              </div>
            </div>
          </div>
          <div className="container-flu">
              <Clientlogos item={aimodel} />
              <Rightlogo item={aimodel2} />
          </div>
      </section>

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact/> */}

      {/* contact end section */}
    </div>
  );
}

export default ArtificialIntelligence;
