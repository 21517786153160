// Preloader.js
import React from 'react';
import './preloader.css'




function Preloader({ isLoading }) {
  return (
    <div className={`loading-screen ${isLoading ? 'visible' : 'hidden'}`}>
      <div className='logos'>
        <span className='D'>D</span>
        <span className='G'>G</span>
        <span className='i'>i</span>
      </div>
    </div>
  );
}

export default Preloader;
