import React from 'react';
import './progressbar.css';
import { useState, useEffect } from 'react';

function Progress() {
    const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollTop = window.scrollY;
      const percentage = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(percentage);
    };

    // Listen to scroll events
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <div id="progress-container"
    style={{
       
    }}>
        <div className="progress-fill"
        style={{
            height:"100%",
            backgroundColor:"#ED1C24",
            width: `${scrollPercentage}%`
        }}>

        </div>

    </div>
  )
}

export default Progress;