import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/Blogs-sidebar/sidebar';
import Banner from '../../components/Bannersection/banner';
import { FaUser, FaTags } from "react-icons/fa";
import {Helmet} from "react-helmet";

const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

// Function to generate a slug from a title
const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // Remove invalid chars
    .replace(/\s+/g, '-')        // Collapse whitespace and replace by -
    .replace(/-+/g, '-');        // Collapse dashes
};

const BlogPost = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const pagename = blog ? [
    {
      pagetitle: decodeHtml(blog.title.rendered),
      subtitle: "Single Blog",
    }
  ] : [];

  useEffect(() => {
    // Fetch the specific blog post by slug
    fetch(`https://blogs.dgiworx.in/wp-json/wp/v2/posts?slug=${slug}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(async (data) => {
        if (data.length === 0) {
          throw new Error('No blog post found with this slug.');
        }

        let updatedBlog = { ...data[0] };
        if (updatedBlog.featured_media !== 0) {
          // Fetch featured image URL
          const mediaResponse = await fetch(updatedBlog._links['wp:featuredmedia'][0].href);
          const mediaData = await mediaResponse.json();
          const mediaUrl = mediaData.guid.rendered;
          updatedBlog.featured_media_url = mediaUrl;
        }
        if (updatedBlog.categories.length > 0) {
          // Fetch category name
          const categoryResponse = await fetch(updatedBlog._links['wp:term'][0].href);
          const categoryData = await categoryResponse.json();
          const categoryName = categoryData[0].name;
          updatedBlog.category_name = categoryName;
        }
        if (updatedBlog.author !== 0) {
          // Fetch author name
          const authorResponse = await fetch(updatedBlog._links.author[0].href);
          const authorData = await authorResponse.json();
          const authorName = authorData.name;
          updatedBlog.author_name = authorName;
        }
        setBlog(updatedBlog);
      })
      .catch((error) => console.error('Error fetching blog:', error));

    // // Fetch latest posts
    // fetch('https://blogs.dgiworx.in/wp-json/wp/v2/posts?per_page=3')
    //   .then((response) => response.json())
    //   .then((data) => setLatestPosts(data))
    //   .catch((error) => console.error('Error fetching latest posts:', error));

    // // Fetch categories
    // fetch('https://blogs.dgiworx.in/wp-json/wp/v2/categories')
    //   .then((response) => response.json())
    //   .then((data) => setCategories(data))
    //   .catch((error) => console.error('Error fetching categories:', error));

    // // Fetch tags
    // fetch('https://blogs.dgiworx.in/wp-json/wp/v2/tags')
    //   .then((response) => response.json())
    //   .then((data) => setTags(data))
    //   .catch((error) => console.error('Error fetching tags:', error));

  }, [slug]);

  if (!blog) return <div>Loading...</div>;

  const canonicalUrl = `https://dgiworx.in/blog/${slug}`;


  return (
    <main>
     <Helmet>
         <title>{blog.yoast_head_json.title}</title>
         <meta name="description" content={blog.yoast_head_json.description} />
         <link rel="canonical" href={canonicalUrl} />
         <meta property="og:title" content={blog.yoast_head_json.title} />
         <meta property="og:description" content={blog.yoast_head_json.description} />
         <meta property="og:type" content="article" />
         <meta property="og:url" content={window.location.href} />
         <meta property="og:image" content={blog.yoast_head_json.og_image[0].url} />
         <meta property="og:image:width" content={blog.yoast_head_json.og_image[0].width} />
         <meta property="og:image:height" content={blog.yoast_head_json.og_image[0].height} />
         <meta property="og:site_name" content={blog.yoast_head_json.og_site_name} />
         <meta property="article:published_time" content={blog.yoast_head_json.article_published_time} />
         <meta property="article:modified_time" content={blog.yoast_head_json.article_modified_time} />
         <meta property="article:author" content={blog.yoast_head_json.author} />
         <meta property="article:section" content={blog.yoast_head_json.category_name} />
         <meta name="twitter:card" content={blog.yoast_head_json.twitter_card} />
         <meta name="twitter:site" content={blog.yoast_head_json.twitter_misc['Written by']} />
         <meta name="twitter:creator" content={blog.yoast_head_json.twitter_misc['Written by']} />
         <meta name="twitter:title" content={blog.yoast_head_json.title} />
         <meta name="twitter:description" content={blog.yoast_head_json.description} />
         <meta name="twitter:image" content={blog.yoast_head_json.og_image[0].url} />

         <script type="application/ld+json">
            {JSON.stringify(blog.yoast_head_json.schema)}
         </script>
    </Helmet>

      <Banner items={pagename} />
     
      <section className="postbox__area pt-145 pb-105">
         <div className="container">
            <div className="row">
               <div className="col-xxl-8 col-xl-8 col-lg-7">
                  <div className="postbox-main-wrapper mb-35 pr-30">
                     <div className="postbox-meta-wrapper">
                        <article className="postbox-item format-image mb-50 transition-3">
                           <div className="postbox-thumb w-img">
                              <img src={blog.featured_media_url ? blog.featured_media_url : 'default-image-url'} alt={decodeHtml(blog.title.rendered)} />
                           </div>
                           <div className="postbox-content">
                              <div className="postbox-meta">
                                 <span><p><FaUser /> {blog.author_name}</p></span>
                                 <span><FaTags /> {blog.category_name}</span>
                                 {/* Add comments count */}
                              </div>
                              <h3 className="postbox-title">{decodeHtml(blog.title.rendered)}</h3>
                              <div className="postbox-text">
                                 <div dangerouslySetInnerHTML={{ __html: blog.content.rendered }} />
                                 
                                 {/* Add other blog content sections */}
                              </div>
                           </div>
                        </article>
                     </div>
                     {/* Add other sections like tags, author info, comments */}
                  </div>
               </div>
               <div className="col-xxl-4 col-xl-4 col-lg-5">
                  <div className="sidebar-wrapper mb-60">
                     <Sidebar latestPosts={latestPosts} categories={categories} tags={tags} />
                  </div>
               </div>
            </div>
         </div>
      </section>
      
   </main>
  );
};

export default BlogPost;
