import React from 'react';

const NotFound = () => {
  return (
    <div>

         <section className="error-area tp-erorr-wrap">
            <div className="tp-erorr-bg" data-background="assets/img/thumbs/error-bg-1.jpg"></div>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-erorr-content text-center">
                        <h4 className="tp-erorr-title">4<span>0</span>4</h4>
                        <span>Ooops....</span>
                        <p>We are sorry, something went wrong</p>
                        <a className="tp-btn" href="/">Go Home</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
    </div>
  );
}

export default NotFound;
