import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Rightlogo = ({ item }) => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        className: "center",
        autoplay: true,
        speed: 8000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        rtl: true,
        responsive: [
            {
                breakpoint: 1285, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className='' >

            <Slider {...settings}>
                {item.map((item, index) => (
                    <div className="logo-card" key={index}>
                        <div className="">
                        <a href={item.url} target={item.target}>
                            <img src={item.logourl} alt={item.title} loading='lazy' />
                            </a>
                        </div>
                    </div>
                ))}
            </Slider>
        </div >
    )
}
export default Rightlogo;