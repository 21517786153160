import React from 'react';
import { BsFillEnvelopeFill, BsBehance, BsWhatsapp } from "react-icons/bs";
import { AiFillPhone, AiFillInstagram, } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6"
import { FaLocationDot } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
import { FaXTwitter } from "react-icons/fa6";
function FooterIn() {
   return (
      <div>
         {/* <!-- footer-area-start --> */}
         <footer>
            <div className="footer-area">
               <div className="tp-footer-top pt-115 pb-70 theme-bg" data-background="assets/img/footer/one/footer-bg-1.jpg">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                           <div className="tp-footer-widget footer-col-1 mb-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                              <div className="tp-footer-widget-logo mb-15">
                                 <a href="/">
                                    <img src="assets/img/logo/dgilogo/dgi.png" alt="dgiworx" loading='laze' />
                                 </a>
                              </div>
                              <div className="tp-footer-widget-content">
                                 <p>DGi WORX, a leading name in IT & Digital Services, consistently delivers exceptional technology solutions.</p>
                                 <div className="tp-footer-widget-newsletter-social">
                                    <span className='follow'>Follow us:</span>
                                    <a href="https://www.instagram.com/dgiworx/" target="_blank"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                    <a href="https://www.facebook.com/dgiworx" target="_blank"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                    <a href="https://www.behance.net/dgiworx" target="_blank"><i ><BsBehance className="fa-brands fa-twitter" /></i></a>
                                    <a href="https://twitter.com/dgiworx" target="_blank"><i ><FaXTwitter className="fa-brands fa-twitter" /></i></a>
                                    <a href="https://www.linkedin.com/company/67087770/" target="_blank"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                    {/* <a href="https://www.youtube.com/channel/UCxN2y-6L8tL_ZNXYPq8_n3g" target="_blank"><i><TfiYoutube className="fa-brands fa-youtube-f" /></i></a> */}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                           <div className="tp-footer-widget tp-footer-col-2 mb-40 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".6s">
                              <h4 className="tp-footer-widget-title mb-30">Company</h4>
                              <div className="tp-footer-widget-link">

                                 <ul>
                                    <li><a href="/about"><i className="fa-sharp fa-solid fa-plus"></i> About Us</a></li>
                                    <li><a href="/projects"><i className="fa-sharp fa-solid fa-plus"></i> Our Projects</a></li>
                                    <li><a href="/socialmedia"><i className="fa-sharp fa-solid fa-plus"></i>Social Media</a></li>
                                    <li><a href="/seo"><i className="fa-sharp fa-solid fa-plus"></i> SEO</a></li>
                                    {/* <li><a href=""><i className="fa-sharp fa-solid fa-plus"></i> Blog</a></li> */}
                                    <li><a href="/blog"><i className="fa-sharp fa-solid fa-plus"></i> Blog</a></li>
                                    <li><a href="/contact"><i className="fa-sharp fa-solid fa-plus"></i> Contact</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="tp-footer-widget tp-footer-col-3 mb-40 wow fadeInUp" data-wow-duration="1.4s" data-wow-delay=".8s">
                        <h4 className="tp-footer-widget-title mb-40">Our Navigation</h4>
                        <div className="tp-footer-widget-gallery">
                           <div className="tp-footer-widget-gallery-all">
                              <div className="tp-footer-widget-gallery-single-item">
                                 <a href="#" className="tp-footer-widget-gallery-item">
                                    <div className="tp-footer-widget-gallery-thumb">
                                       <img src="../assets/img/footer/one/gallery/fw-g-1.jpg" alt=""/>
                                       <div className="tp-footer-widget-gallery-social">
                                          <i className="fa-brands fa-linkedin-in"></i>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                              <div className="tp-footer-widget-gallery-single-item">
                                 <a href="#" className="tp-footer-widget-gallery-item">
                                    <div className="tp-footer-widget-gallery-thumb">
                                       <img src="../assets/img/footer/one/gallery/fw-g-2.jpg" alt=""/>
                                       <div className="tp-footer-widget-gallery-social">
                                          <i className="fa-brands fa-linkedin-in"></i>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                              <div className="tp-footer-widget-gallery-single-item">
                                 <a href="#" className="tp-footer-widget-gallery-item">
                                    <div className="tp-footer-widget-gallery-thumb">
                                       <img src="../assets/img/footer/one/gallery/fw-g-3.jpg" alt=""/>
                                       <div className="tp-footer-widget-gallery-social">
                                          <i className="fa-brands fa-linkedin-in"></i>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                              <div className="tp-footer-widget-gallery-single-item">
                                 <a href="#" className="tp-footer-widget-gallery-item">
                                    <div className="tp-footer-widget-gallery-thumb">
                                       <img src="../assets/img/footer/one/gallery/fw-g-4.jpg" alt=""/>
                                       <div className="tp-footer-widget-gallery-social">
                                          <i className="fa-brands fa-linkedin-in"></i>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                              <div className="tp-footer-widget-gallery-single-item">
                                 <a href="#" className="tp-footer-widget-gallery-item">
                                    <div className="tp-footer-widget-gallery-thumb">
                                       <img src="../assets/img/footer/one/gallery/fw-g-5.jpg" alt=""/>
                                       <div className="tp-footer-widget-gallery-social">
                                          <i className="fa-brands fa-linkedin-in"></i>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                              <div className="tp-footer-widget-gallery-single-item">
                                 <a href="#" className="tp-footer-widget-gallery-item">
                                    <div className="tp-footer-widget-gallery-thumb">
                                       <img src="../assets/img/footer/one/gallery/fw-g-6.jpg" alt=""/>
                                       <div className="tp-footer-widget-gallery-social">
                                          <i className="fa-brands fa-linkedin-in"></i>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                           <div className="tp-footer-widget tp-footer-col-4 mb-40 wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1s">
                              <h4 className="tp-footer-widget-title mb-20">Contact Details</h4>
                              <div className="tp-footer-widget-newsletter-wrap">
                                 {/* <form action="#">
                              <div className="tp-footer-widget-newsletter-wrapper">
                                 <div className="tp-footer-widget-newsletter-input">
                                    <input type="email" placeholder="Get News & Updates"/>
                                 </div>
                                 <div className="tp-footer-widget-newsletter-submit">
                                    <button>
                                       <i className="fa-regular fa-arrow-right"></i>
                                    </button>
                                 </div>
                              </div>
                           </form> */}
                                 <div className="tp-footer-widget-newsletter-content">
                                    <div className="tp-footer-widget-content-list">
                                       <div className="tp-footer-widget-content-list-item">
                                          <i ><BsFillEnvelopeFill className="fa-solid fa-envelope" /></i> <a href="mailto:support@dgiworx.in"><span className="__cf_email__">support@dgiworx.in</span></a>
                                       </div>
                                       <div className="tp-footer-widget-content-list-item">
                                          <i><AiFillPhone className="fa-solid fa-square-phone" /></i><a href="tel:0123456789">+91 044 4203 1666</a>
                                       </div>
                                       <div className="tp-footer-widget-content-list-item">
                                          <i ><BsWhatsapp className="fa-solid fa-envelope" /></i><a href="https://wa.me/+919884896666" target='_blank'>+91 98848 96666</a>
                                       </div>
                                       <div className="tp-footer-widget-content-list-item">
                                          <i><FaLocationDot className="fa-solid fa-location-dot" /></i> <p>No.5/11, 1st Floor, 22nd Cross St, Indira Nagar, Adyar, Chennai, TamilNadu, India.</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="tp-footer-bottom">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-6 col-md-7">
                           <div className="tp-footer-copyright">
                              <span>Copyright © 2024 <b>DGi WORX</b> All Rights Reserved </span>
                           </div>
                        </div>
                        <div className="col-lg-6 col-md-5">
                           <div className="tp-footer-terms">
                              <a href="/terms_of_use">Terms of Use</a>
                              <a href="/privacy_policy">Privacy Policy</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
         
         {/* <!-- footer-area-end --> */}
      </div>
   )
}

export default FooterIn;