import React from 'react';
import Particlesanimation from '../Particlesanimation/particlesanimation';


const Banner=({items})=> {
  return (
    <div>
        {/* <!-- breadcrumb-area-start --> */}
      <section className="breadcrumb-area breadcrumb-wrap">
        <div className="breadcrumb-bg" data-background="assets/img/breadcrumb/breadcrumb-bg-1.jpg"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
            {items.map((items, index) => (
              <div className="tpbreadcrumb" key={index}>
                <div className="breadcrumb-link mb-15">
                  <span className="breadcrumb-item-active"><a href="/">Home</a></span>
                  <span> / {items.subtitle}</span>
                </div>
                <h1 className="breadcrumb-title">{items.pagetitle}</h1>
              </div>
              ))}
            </div>
          </div>
          <Particlesanimation/>
        </div>
        <div className="breadcrumb-shape">
          <div className="breadcrumb-shape-1 wow fadeInRight" data-wow-duration="1.8s" data-wow-delay=".4s">
            <img src="assets/img/breadcrumb/breadcrumb-shape-1.png" alt="" />
          </div>
          <div className="breadcrumb-shape-4 wow slideInRight" data-wow-duration="1.2s" data-wow-delay=".1s">
            <img src="assets/img/breadcrumb/breadcrumb-shape-3.png" alt="" />
          </div>
          <div className="breadcrumb-shape-5 wow slideInRight" data-wow-duration="1.4s" data-wow-delay=".3s">
            <img src="assets/img/breadcrumb/breadcrumb-shape-2.png" alt="" />
          </div>
        </div>

      </section>
      {/* <!-- breadcrumb-area-end --> */}
    </div>
  )
}

export default Banner;
