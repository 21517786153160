import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function SocialForm() {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_IN_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_IN_EMAIL_TEMPLATE_ID,
            e.target, // This should be the HTML form element
            process.env.REACT_APP_EMAILJS_IN_USER_ID,
        )
            .then((result) => {
                console.log(result.text);
                toast.success('Message sent successfully!', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }, (error) => {
                console.log(error.text);
                toast.error('Error sending message. Please try again later.', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });

        // Clear the form after sending the email
        e.target.reset();

    };


    const [platform, setPlatform] = useState('');



    const handlePlatformChange = (e) => {
        setPlatform(e.target.value);
    };
    return (
        <div>
            <section className="form-area tp-faq-wrapper pt-160 pb-55">
                <div className="tp-faq-bg" data-background="assets/img/shape/faq-2-shape-1.jpg"></div>
                <div className="container mt-40">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="tp-section text-center mb-50">
                                <span className="tp-section-sub-title">Drop Us a Line</span>
                                <h2 className="tp-section-title">Need Help? Sure we are Online!</h2>
                                <p>Ready to elevate your social media presence? Fill out the form below to specify your needs and learn more about how we can help you grow on platforms like Facebook, Instagram, and Meta.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="tp-form-wrapper mb-60 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                <div className="tp-form-wrap">
                                    <form id="contactform" action="" name="contactform" method="post" onSubmit={sendEmail}>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="first_name" id="fname" type="text" placeholder="First Name" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="last_name" id="lname" type="text" placeholder="Last Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="email" id="email" type="email" placeholder="Email Address" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="phone" id="phonenumber" type="text" placeholder="Phone Number" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-4 col-md-12">
                                                <div className="tp-form-input" >
                                                    <select name="platform" onChange={handlePlatformChange} required className='col-lg-6 col-md-12'>
                                                        <option value="">Select Platform</option>
                                                        <option value="facebook">Facebook</option>
                                                        <option value="instagram">Instagram</option>
                                                        {/* <option value="meta">Meta</option> */}
                                                        <option value="youtube">YouTube</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12">
                                                <div className="tp-form-input">
                                                    <select name="service" required className='col-lg-6 col-md-12'>
                                                        <option value="">Select Service</option>
                                                        <option value="likes">Likes</option>
                                                        {platform === 'youtube' ? <option value="subscribers">Subscribers</option> : <option value="followers">Followers</option>}
                                                        {/* <option value="shares">Shares</option> */}
                                                        <option value="views">Views</option>
                                                        <option value="comments">Comments</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12">
                                                <div className="tp-form-input">
                                                    <input name="count" id="count" type="text" placeholder="Desired Count" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-form-input">
                                            <input name="profile_url" id="profile_url" type="text" placeholder="Profile URL" required />
                                        </div>
                                        {/* <div className="tp-form-input">
                                            <input name="user_name" id="user_name" type="text" placeholder="Username" required />
                                        </div> */}
                                        <div className="tp-form-btn">
                                            <button className="tp-btn">Send Your Request</button>
                                            <ToastContainer />
                                        </div>
                                    </form>
                                </div>
                                <div className="tp-form-shape">
                                    <img src="assets/img/shape/contact-2-shape-1.png" alt="" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}



export default SocialForm;

