import React from 'react'
import Componcontact from '../../components/Contact/contact-compon';
import { BsBook, BsFillPersonFill, BsPcDisplayHorizontal, BsCashCoin, BsPersonWorkspace, BsShieldCheck, BsRocketTakeoff, } from "react-icons/bs";
import { TbTargetArrow, TbEyeSearch } from 'react-icons/tb';
import { BiTimer } from "react-icons/bi";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import { FaHandshakeSimple } from 'react-icons/fa6';
import Banner from '../../components/Bannersection/banner';
import {Helmet} from "react-helmet";
function About() {

    const pagename = [
        {
            pagetitle: "About Us",
            subtitle: "About",
        }
    ]
    return (
        <div>
            <Helmet>
                <title>About DGi WORX - Innovative IT Solutions Company</title>
                <meta name="description" content="Learn more about DGi WORX, a leading IT solutions company specializing in innovative technology solutions. Discover our values, team, and commitment to delivering exceptional IT services." />
                <meta name="keywords" content="best software development company, custom software development, Cloud saas Software, website development, custom website, ecommerce website, crm software, sharepoint, software maintainance, design works, logo creation, branding, social media, digital marketing company, technology training, domain training, mobile app development, android app development, iOS app development, Dgiworx, Professional team, about us, IT solutions company, technology solutions, innovation, company values, team, IT services, about DGi WORX" />
                <meta property="og:title" content="About DGi WORX - Innovative IT Solutions Company" />
                <meta property="og:description" content="Learn more about DGi WORX, a leading IT solutions company specializing in innovative technology solutions. Discover our values, team, and commitment to delivering exceptional IT services." />
                <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg"/>
                <meta property="og:url" content="https://dgiworx.in/about" />
               
                {/* Add more meta tags as needed */}
            </Helmet>


            <main>



                {/* <!-- breadcrumb-area-start --> */}


                <Banner items={pagename} />

                {/* <!-- breadcrumb-area-end --> */}


                <section className="about-area tp-about-two py-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="tp-about-two-wrap mb-10 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-about-two-thumb">
                                        <div className='tp-about-circle'>
                                            <div className='tp-about-circle-two'>
                                                <img src="assets/img/about/about-1.jpg" alt="" />
                                            </div>
                                        </div>
                                        {/* <img src="assets/img/service/seo/01.jpg" alt="" loading='lazy' /> */}
                                    </div>
                                    <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/service/seo/02.jpg" alt="" loading='lazy' />
                                    </div>
                                    <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                                        <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="tp-about-two-wrapper mb-10 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-section mb-40">
                                        <span className="tp-section-sub-title">Innovative Company</span>
                                        <h4 className="tp-section-title">Discover Our Story</h4>
                                        <div className="tp-section-title-wrapper">
                                            <p>We are a leading software company dedicated to pioneering innovation in the digital world. With a passion for technology and a commitment to excellence,
                                                we specialize in crafting cutting-edge software solutions that transform businesses. Our team of skilled developers, designers, and engineers work
                                                collaboratively to create custom software tailored to meet the unique needs of our clients.<br /><br />We thrive on challenges and take
                                                pride in delivering robust, scalable, and user-friendly software applications. At DGi WORX, we are driven by the belief that technology
                                                should empower and simplify, and we are committed to helping businesses achieve their goals through innovative software solutions.
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="tp-about-two-list">
                                        <ul>
                                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Improved Online Visibility</li>
                                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Organic Traffic Growth</li>
                                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Keyword Optimization</li>
                                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>On-Page and Technical SEO</li>
                                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Content Marketing</li>
                                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Data-Driven Insights</li>
                                        </ul>
                                    </div> */}
                                    <div className="tp-section-title-info d-flex align-items-center mt-30">
                                        <div className="tp-section-title-info-icon">
                                            <i ><BsRocketTakeoff className="flaticon-project" /></i>
                                        </div>
                                        <div className="tp-section-title-info-text">
                                            <span>Reliable Software Solutions for Sustainable Business Growth</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tp-about-two-shape">
                        <div className="tp-about-two-shape-4">
                            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
                        </div>
                    </div>
                </section>



                {/* <!-- Our Business Ethos --> */}
                <section className="feature-area py-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="tp-section tp-section-white text-center mb-100">
                                    <span className="tp-section-sub-title">Cultivating Our Values</span>
                                    <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">Our Business Ethos</h2>
                                    <h3 className='section-sub-title col-lg-10 col-sm-12 text-dark mx-auto'>Guiding principles that define our commitment to integrity, excellence, and client satisfaction.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="tp-feature-4-item mb-50">
                                    <div className="tp-feature-4-thumb" data-background="assets/img/feature/four/feature-4-thumb-1.jpg">
                                    </div>
                                    <div className="tp-feature-4-item-wrapper">
                                        <div className="tp-feature-4-item-icon">
                                            <i ><TbTargetArrow className="flaticon-product-design" /></i>
                                        </div>
                                        <div className="tp-feature-4-item-content">
                                            <h4 className="tp-feature-4-item-title">OUR MISSION</h4>
                                            <p>At DGi WORX, our mission is to revolutionize the digital landscape by crafting cutting-edge software solutions.
                                                We are dedicated to empowering businesses through innovation, driving growth, and enhancing user experiences.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="tp-feature-4-item mb-50">
                                    <div className="tp-feature-4-thumb" data-background="assets/img/feature/four/feature-4-thumb-1.jpg">
                                    </div>
                                    <div className="tp-feature-4-item-wrapper">
                                        <div className="tp-feature-4-item-icon">
                                            <i><TbEyeSearch className="flaticon-solution" /></i>
                                        </div>
                                        <div className="tp-feature-4-item-content">
                                            <h4 className="tp-feature-4-item-title">OUR VISION</h4>
                                            <p>Our vision is to be a global leader in software innovation, where technology enriches and simplifies lives. To achieve this, we
                                                continually invest in research and development, forge strategic partnerships, and always prioritize the customer.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="tp-feature-4-item mb-50">
                                    <div className="tp-feature-4-thumb" data-background="assets/img/feature/four/feature-4-thumb-1.jpg">
                                    </div>
                                    <div className="tp-feature-4-item-wrapper">
                                        <div className="tp-feature-4-item-icon">
                                            <i><FaHandshakeSimple className="flaticon-technology" /></i>
                                        </div>
                                        <div className="tp-feature-4-item-content">
                                            <h4 className="tp-feature-4-item-title">OUR VALUES</h4>
                                            <p>At DGi WORX, our guiding principles - innovation, integrity, customer-centricity, and excellence - shape our software company. They
                                                ensure we uphold ethical standards, foster improvement, and prioritize customer satisfaction.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Our Business Ethos-end --> */}



                {/* <!-- Why Choose Us --> */}
                <section className="feature-area   py-50  p-relative">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="tp-section tp-section-white text-center mb-100">
                                    <span className="tp-section-sub-title">What We Solve</span>
                                    <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">Why Choose Us</h2>
                                    <h3 className='section-sub-title col-lg-10 col-sm-12 text-dark mx-auto'>Our meticulous precision and unwavering commitment to detail distinguish us. Here are the compelling reasons to select us for your projects.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 dgiginitespeciality">
                                <div className="tp-feature-item mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-feature-icon">
                                        <i><BsFillPersonFill className="flaticon-solution-1" /></i>
                                    </div>
                                    <div className="tp-feature-content">
                                        <h4 className="tp-feature-content-title">Customer is our priority</h4>
                                        <p>Our unwavering commitment is to put our customers at the forefront of everything we do.</p>
                                    </div>
                                    <div className="tp-feature-shape-two">
                                        <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 dgiginitespeciality">
                                <div className="tp-feature-item mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-feature-icon">
                                        <i><BsPersonWorkspace className="flaticon-solution-1" /></i>
                                    </div>
                                    <div className="tp-feature-content">
                                        <h4 className="tp-feature-content-title">Guaranteed quality work</h4>
                                        <p>We stand firmly behind our work with an unwavering guarantee of excellence in every project.</p>
                                    </div>
                                    <div className="tp-feature-shape-two">
                                        <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 dgiginitespeciality">
                                <div className="tp-feature-item mb-30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-feature-icon">
                                        <i><BiTimer className="flaticon-solution-1" /></i>
                                    </div>
                                    <div className="tp-feature-content">
                                        <h4 className="tp-feature-content-title">On-time delivery</h4>
                                        <p>Meeting deadlines is our promise, ensuring your projects are delivered as scheduled.</p>
                                    </div>
                                    <div className="tp-feature-shape-two">
                                        <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 dgiginitespeciality">
                                <div className="tp-feature-item mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-feature-icon">
                                        <i>< HiOutlineCubeTransparent className="flaticon-solution-1" /></i>
                                    </div>
                                    <div className="tp-feature-content">
                                        <h4 className="tp-feature-content-title">Transparency</h4>
                                        <p>We believe in open and honest communication, providing clarity at every step of your journey with us.</p>
                                    </div>
                                    <div className="tp-feature-shape-two">
                                        <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 dgiginitespeciality">
                                <div className="tp-feature-item mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-feature-icon">
                                        <i><BsBook className="flaticon-solution-1" /></i>
                                    </div>
                                    <div className="tp-feature-content">
                                        <h4 className="tp-feature-content-title">Technical expertise</h4>
                                        <p>Our team comprises seasoned experts with a deep understanding of the latest technologies.</p>
                                    </div>
                                    <div className="tp-feature-shape-two">
                                        <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 dgiginitespeciality">
                                <div className="tp-feature-item mb-30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="tp-feature-icon">
                                        <i><BsShieldCheck className="flaticon-solution-1" /></i>
                                    </div>
                                    <div className="tp-feature-content">
                                        <h4 className="tp-feature-content-title">Innovation</h4>
                                        <p>We continuously push boundaries, delivering innovative solutions to drive your success.</p>
                                    </div>
                                    <div className="tp-feature-shape-two">
                                        <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tp-feature-shape">
                        <div className="tp-feature-shape-one w-img">
                            <img src="assets/img/feature/one/features-bg.png" alt="" loading="lazy" />
                        </div>
                    </div>
                </section>
                {/* <!-- Why Choose Us-end --> */}

                {/* <Componcontact /> */}

            </main>
        </div>
    )
}

export default About;