
import React, { useEffect, useState } from 'react';
import './App.css';
// import axios from 'axios';
// import './assets/css/main.css';
// import './assets/css/animate.css'
import {BrowserRouter as Router , Routes, Route} from 'react-router-dom';
//import Header from './components/Header/header';
import HeaderIN from './components/Header/headerIN';
//import HeaderAU from './components/Header/headerAU';
//import HeaderUS from './components/Header/headerUS';
//import HeaderGL from './components/Header/header';
// import Footer from './components/footer/footer';
import About from './pages/About/about';
import Home from './pages/Home/home';
import Service from './pages/Service/service';
import Projects from './pages/Projects/projects'
import Socialmedia from './pages/Socialmedia/socialmedia';
import Ssm from "./pages/Ssm/Ssm";
import Dgiginite from './pages/Dgiginite/dgiginite';
import Appdevelopment from './pages/Service/subpages/AppDevelopment/appdevelopment';
import Webdevelopment from './pages/Service/subpages/WebDevelopment/webdevelopment';
import Cloudservices from './pages/Service/subpages/CloudServices/cloudservices';
import Automation from './pages/Service/subpages/Automation/automation';
import Migrationservices from './pages/Service/subpages/Migration Services/migrationservices';
import Dataanalytics from './pages/Service/subpages/DataAnalytics/dataanalytics';
import Ecommerce from './pages/Service/subpages/Ecommerce/ecommerce';
import Design from './pages/Service/subpages/DesignWorks/design';
import Seo from './pages/Service/subpages/SEO/seo';
import Facebook from "./pages/Socialmedia/subpages/Facebook/facebook"
import Instagram from "./pages/Socialmedia/subpages/Instagram/instagram"
import Youtube from './pages/Socialmedia/subpages/youtube/youtube';
import FooterIn from './components/footer/footerIN';
//import FooterUs from './components/footer/footerUS';
//import FooterAu from './components/footer/footerAU';
import Progress from './components/progressbar/progress';
// import Contact from './pages/Contact/contact';
//import ContactUS from './pages/Contact/contactUS';
//import ContactAU from './pages/Contact/contactAU';
import ContactIN from './pages/Contact/contactIN';
import Privacypolicy from './pages/Privacypolicy/privacypolicy';
import Terms from './pages/Terms_of_Use/terms';
import ContactFormIN from './components/Contact/contactformIN';
//import ContactFormUS from './components/Contact/contactformUS';
//import ContactFormAU from './components/Contact/contactformAU';
import MessengerCustomerChat from 'react-messenger-customer-chat';
//import { ImSpinner9 } from 'react-icons/im';
// import Preloader from './components/Preloder/preloder';
import Scrolltop from './components/Scrollarrow/scrolltop';
import Pagepreloader from './components/Preloder/pagepreloder';
//import Countryselect from './components/Countryselect/countryselect';
import SocialForm from './components/Contact/SocialForm';
import UsePageTracking from './Hooks/pageTracking';
import { components } from 'react-select';
import ArtificialIntelligence from './pages/Service/subpages/Artificialintelligence/ArtificialIntelligence';
import BlogList from './pages/Blog/bloglist';
import BlogPost from './pages/Blog/blogpost';
import NotFound from './components/NotFound/notfound';




function App() {
  const pageId = process.env.REACT_APP_FACEBOOK_PAGE_ID;
	const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const [loading, setLoading] = useState(false);
  // const [userCountry, setUserCountry] = useState(null);
  // const apiToken = process.env.REACT_APP_API_TOKEN;
  // const shouldRenderContactForm = window.location.pathname !== '/contact';
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(`https://ipinfo.io?token=${apiToken}`)
  //     .then((response) => {
  //       const data = response.data;
  //       const detectedCountry = data.country;
  //       setUserCountry(detectedCountry);
  //       setTimeout(() => {
  //         setIsLoading(false);
  //     }, 0);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching user IP information', error);
  //     });
  // }, []);

  useEffect(() => {
    setLoading(true);
    // Simulate an async operation (like data fetching)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust time as needed

    return () => clearTimeout(timer);
  },[]);


  // Determine the header component based on the user's country
  // let HeaderComponent;
  // if (userCountry === 'IN') {
  //   HeaderComponent = <HeaderIN />;
  // } else if (userCountry === 'US') {
  //   HeaderComponent = <HeaderUS />;
  // } else if (userCountry === 'AU') {
  //   HeaderComponent = <HeaderAU />;
  // } else {
  //   // Default header for other countries
  //   HeaderComponent = <HeaderIN />
  // }

  
  // let FooterComponent;
  // if (isLoading) {
  //   HeaderComponent = <Pagepreloader/>;
  // } else
  // if (userCountry === 'IN') {
  //   FooterComponent = <FooterIn />;
  // } else if (userCountry === 'US') {
  //   FooterComponent = <FooterUs />;
  // } else if (userCountry === 'AU') {
  //   FooterComponent = <FooterAu />;
  // } else {
    
  //   FooterComponent = <FooterIn/>;
  // }

  // let ContactComponent;

  // if (userCountry === 'IN') {
  //   ContactComponent = <ContactIN />;
  // } 
  // else if (userCountry === 'US') 
  // {
  //   ContactComponent = <ContactUS />;
  // } 
  // else if (userCountry === 'AU') 
  // {
  //   ContactComponent = <ContactAU />;
  // } 
  // else {
   
  //   ContactComponent = <ContactIN/>;
  // }

  // let ContactFormComponent;
  // if (userCountry === 'IN') {
  //   ContactFormComponent = <ContactFormIN />; 
  // } else if (userCountry === 'US') {
  //   ContactFormComponent = <ContactFormUS />; 
  // } else if (userCountry === 'AU') {
  //   ContactFormComponent = <ContactFormAU/>; 
  // } else {
  //   ContactFormComponent = <ContactFormIN/>; 
  // }

// const isFacebookOrYouTubeorInstaPage = () => {
//   const currentUrl = window.location.href.toLowerCase();
//   return (
//     currentUrl.includes("facebook") ||
//     currentUrl.includes("youtube") ||
//     currentUrl.includes("instagram")
//   );
// };
// const isFacebookOrYouTubeorInstaPage2 = () => {
//   const currentUrl = window.location.href.toLowerCase();
//   return (
//     currentUrl.includes("contact") 
//   );
// };


// contact-components hiade and view  function code 

const isSocialMediaPage = () => {
  const currentUrl = window.location.href.toLowerCase();
  return (
    currentUrl.includes("facebook") ||
    currentUrl.includes("youtube") ||
    currentUrl.includes("instagram")
  );
};

const isContactPage = () => {
  const currentUrl = window.location.href.toLowerCase();
  return (
    currentUrl.includes("contact")||
    currentUrl.includes("terms_of_use") ||
    currentUrl.includes("privacy_policy")
      
  )
  
};

const renderForm = () => {
  if (isSocialMediaPage()) {
    return <SocialForm />;
  } else if (isContactPage()) {
    return null;
  } else {
    return <ContactFormIN />;
  }
}

  
  return (
    <div className="">
      
      {loading && <Pagepreloader />}
      <Router>
        <UsePageTracking />
        <Progress />
        <HeaderIN />

        {/* <Header/> */}
        <Routes>
          <Route path="/" element={<Home />}></Route>

          <Route path="about" element={<About />}></Route>
          <Route path="service" element={<Service />}></Route>
          {/* sub pages */}
          <Route path="web-development" element={<Webdevelopment />}></Route>
          <Route path="mobile-app-development" element={<Appdevelopment />}></Route>
          <Route path="cloudservices" element={<Cloudservices />}></Route>
          <Route path="automation" element={<Automation />}></Route>
          <Route
            path="migrationservices"
            element={<Migrationservices />}
          ></Route>
          <Route path="dataanalytics" element={<Dataanalytics />}></Route>
          <Route path="ecommerce" element={<Ecommerce />}></Route>
          <Route path="design" element={<Design />}></Route>
          <Route path="seo" element={<Seo />}></Route>
          <Route path='artificialintelligence' element={<ArtificialIntelligence/>}></Route>

          <Route path="projects" element={<Projects />}></Route>

          <Route path="socialmedia" element={<Socialmedia />}></Route>
          {/* sub pages */}
          <Route path="facebook" element={<Facebook />}></Route>
          <Route path="instagram" element={<Instagram />}></Route>
          <Route path="youtube" element={<Youtube />}></Route>

          <Route path="ssm" element={<Ssm />}></Route>

          <Route path="dgiginite" element={<Dgiginite />}></Route>

          <Route path="contact" element={<ContactIN />}></Route>

          <Route path="privacy_policy" element={<Privacypolicy />}></Route>
          <Route path="terms_of_use" element={<Terms />}></Route>
          <Route  path="blog" element={<BlogList/>} ></Route>
          <Route path="blog/:slug" element={<BlogPost/>} ></Route>
          <Route path="*" element={<NotFound/>} />

          {/* <Route path='' element={</>}></Route> */}
        </Routes>
        <Scrolltop />
        {renderForm()}
        {/* {isFacebookOrYouTubeorInstaPage() ? <ContactFormIN /> : <ContactFormIN />}
        {isFacebookOrYouTubeorInstaPage2() ? null : <ContactFormIN /> } */}
        {/* <Footer/> */}
        <FooterIn />
        <MessengerCustomerChat pageId={pageId} appId={appId} />
      </Router>
    </div>
  );
}

export default App;
