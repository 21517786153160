import React from 'react';
import Faq from '../../../../components/Faq/faq';
import Componcontact from '../../../../components/Contact/contact-compon';
import Banner from '../../../../components/Bannersection/banner';
import { BsBrush, BsBracesAsterisk, BsRocketTakeoff } from "react-icons/bs";
import {Helmet} from "react-helmet";

function Webdevelopment() {

  const pagename = [
    {
      pagetitle: "Website Development",
      subtitle: "Web Development",
    }
  ]
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "What types of websites can you develop?",
      answer: "We specialize in developing a wide range of websites, including personal blogs, business websites, e-commerce platforms, content management systems (CMS), and custom web applications."
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How long does it take to develop a website?",
      answer: "The time it takes to develop a website varies depending on its complexity and requirements. A simple website can be completed in a few weeks, while more complex projects may take several months."
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Do you provide ongoing website maintenance and support?",
      answer: "Yes, we offer ongoing maintenance and support services to ensure your website remains secure, up-to-date, and performs optimally. Our maintenance packages are tailored to your needs."
    },
    {
      Faq_id: "collapsefour",
      data_bs_target: "#collapsefour",
      question: "Can you redesign an existing website?",
      answer: "Absolutely! We can revamp and modernize your existing website, improving its design, functionality, and user experience to meet current standards and trends."
    },
    {
      Faq_id: "collapsefive",
      data_bs_target: "#collapsefive",
      question: "Can you help with SEO (Search Engine Optimization) for my website?",
      answer: "Yes, we offer SEO services to optimize your website for search engines, improving its visibility and ranking in search results. This includes keyword research, on-page optimization, and other SEO strategies."
    },
    {
      Faq_id: "collapsesix",
      data_bs_target: "#collapsesix",
      question: "What is the cost of web development services?",
      answer: "The cost varies depending on the scope and complexity of your project. We provide custom quotes based on your specific requirements and goals. Our aim is to offer competitive pricing while delivering high-quality work."
    },
  ];

  const company = {
    name: "Chennai Web Development Company",
    url: "https://dgiworx.in/web-development",
    logo: "https://dgiworx.in/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+91 9884896666",
    areaServed: "IN",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": company.name,
    "url": company.url,
    "logo": company.logo,
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": company.contactType,
      "telephone": company.telephone,
      "areaServed": {
        "@type": "AdministrativeArea",
        "name": "India"
      }
    },
    "sameAs": company.sameAs,
    "description": "Leading Adyar based web development company, specializing in innovative solutions for businesses in Chennai.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "No.5/11, 22nd Cross Street, Indira Nagar",
      "addressLocality": "Adyar",
      "addressRegion": "Chennai",
      "postalCode": "600020",
      "addressCountry": "IN"
    },
    "founder": {
      "@type": "Person",
      "name": "Nancy Priyadharshini"
    },
    "foundingDate": "2020-04-20",
    "numberOfEmployees": "10",
    "legalName": "DGi WORX",
  };



  return (
    <div>
      <Helmet>
    <title>Chennai Web Development Company | DGi WORX</title>
    <meta name="description" content="Leading Adyar based web development company, specializing in innovative solutions for businesses in Chennai." />
    <meta name="keywords" content="dgiworx, web development company chennai, web developer in chennai, chennai design company, best web development company adyar, website designers chennai" />
    <link rel="canonical" href="https://dgiworx.in/web-development" />
    <meta name="geo.country" content="India" />
    <meta name="geo.placename" content="Adyar, Chennai" />
    <meta property="og:locale" content="en_in" />
    <meta property="og:title" content="Chennai Web Development Company | DGi WORX" />
    <meta property="og:description" content="Leading Adyar based web development company, specializing in innovative solutions for businesses in Chennai." />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg" />
    <meta property="og:url" content="https://dgiworx.in/web-development" />
    
    <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
    </script>
    {/* Add more meta tags as needed */}
</Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mt-10 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                      <img src="assets/img/service/web_development/01.jpg" alt="web development service" loading='lazy' />
                    </div>
                  </div>

                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/service/web_development/02.jpg" alt="mumbai web development company" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="software company chennai" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-10 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">Innovative web solutions</span>
                  <h2 className="tp-section-title">WEB DEVELOPMENT</h2>
                  <div className="tp-section-title-wrapper">
                    <p>DGi WORX is a leading web design company in Chennai, providing top-tier website development services and delivering cost-effective, high-performing solutions tailored to each 
                      customer's needs. Our dedicated team ensures stringent quality standards are met through expertise in cutting-edge technologies, constantly pushing the boundaries of web development.
                      <br /><br />
                      Our reputation has been built on an unwavering commitment to an innovative approach in crafting web applications. As a premier web design company in Chennai, we seamlessly integrate 
                      cutting-edge web technology with creative expertise, collaborating closely with clients across industries to drive measurable outcomes and boost online sales.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="check list" loading='lazy' /></span>Expertise in Technologies</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="check list" loading='lazy' /></span>Responsive Design</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="check list" loading='lazy' /></span>Customization and Scalability</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="check list" loading='lazy' /></span>Strong Portfolio</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="check list" loading='lazy' /></span>Effective Communication</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="check list" loading='lazy' /></span>Security and Compliance</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/project">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="chennai software company" loading='lazy' />
          </div>
        </div>
      </section>

      {/* <!-- about-area-end --> */}

      <section className='py-50'>
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Crafting digital experiences that exceed expectations</span>
                <h2 className="tp-section-title">Our Web Development Services</h2>
                <p>Transforming your unique ideas into dynamic, user-centric web solutions that drive success and engagement.</p>
              </div>
            </div>
          </div>
          <div>
            <div className='sub-services  mb-80'>
              <div className='row'>
                <div className="col-lg-4 col-md-5 col-sm-12 ">
                  <div className=" text-center sub-service-carde mb-20" >

                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img src="assets/img/service/web_development/static_website_development.jpg" alt='static website development' loading='lazy' />
                      <div className="bottom-corner"></div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12  ">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">STATIC WEBSITE DEVELOPMENT</h4>
                        <p>DGi WORX recognizes the significance of a website's initial impression and endeavors to leave a lasting impact with our static web development services. As a leading 
                          website design company, our team of expert website developers conducts a thorough analysis of your business needs, crafting bespoke, user-friendly websites that authentically 
                          mirror your brand identity. Furthermore, our static websites prioritize swift loading times and compatibility across various devices and browsers, guaranteeing a seamless 
                          user experience.<br /><br />Our team of skilled designers and developers leverages their expertise to create visually striking and highly functional static websites. We understand 
                          that each business is unique, and we take the time to understand your goals and requirements to ensure that the design we craft is tailor-made for your brand. With our static web 
                          designing services, we ensure that your online presence is a true reflection of your brand identity. Trust DGi WORX for a web development service that sets your business 
                          apart.</p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>


            <div className='sub-services mb-80'>
              <div className='row'>
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2" >
                  <div className=" text-center sub-service-carde mb-20" >

                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img src="assets/img/service/web_development/dynamic_website_development.jpg" alt='dynamic website development' loading='lazy' />
                      <div className="bottom-corner"></div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">DYNAMIC WEBSITE DEVELOPMENT</h4>
                        <p>At DGi WORX, our dynamic website designing services are meticulously tailored to meet the distinct requirements of each client. Our forte lies in crafting dynamic website 
                          designs that seamlessly combine aesthetics with functionality, ensuring an exceptional user experience.<br /><br />In today's dynamic digital ecosystem, user engagement is 
                          paramount. Our dynamic website designs are not only responsive, adapting seamlessly to various screen sizes, but they also captivate users with interactive elements. From 
                          eye-catching visuals to intuitive navigation, we ensure your visitors stay engaged.<br /><br />DDGi WORX is your digital partner on the journey to enhancing your online presence. 
                          Our dynamic website designing services are designed to elevate your brand, boost user engagement, and ultimately drive success.</p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className='sub-services mb-80'>
              <div className='row'>
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className=" text-center sub-service-carde mb-20" >

                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img src="assets/img/service/web_development/ecommerce_website_development.jpg" alt='ecommerce website development' loading='lazy' />
                      <div className="bottom-corner"></div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">E-COMMERCE WEBSITE DEVELOPMENT</h4>
                        <p>At DGi WORX, we're your trusted partner in realizing your e-commerce aspirations. Our ecommerce website development services are dedicated to empowering your journey towards 
                          online success. Our team of seasoned experts is committed to crafting remarkable online stores that seamlessly combine aesthetics with impeccable functionality.<br /><br />We 
                          understand that every business is unique, and so are its e-commerce needs. Our forte lies in creating customized ecommerce development solutions that cater specifically to your 
                          business requirements. Whether you're starting from scratch or looking to enhance an existing online store, our expertise is at your service.<br /><br />Our e-commerce websites 
                          not only look great but also perform seamlessly. We prioritize functionality, ensuring your store operates flawlessly, handles transactions securely, and scales with your 
                          business.</p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='sub-services mb-10'>
              <div className='row'>
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20" >

                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img src="assets/img/service/web_development/custom_website_development.jpg" alt='custom website development' loading='lazy' />
                      <div className="bottom-corner"></div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">CUSTOM WEBSITE DEVELOPMENT</h4>
                        <p>We're your trusted partner for custom website development, dedicated to paving the way for your online success. As a premier site development company, our expert team of website 
                          developers is on a mission to craft exceptional websites that not only boast captivating aesthetics but also deliver flawless functionality.<br /><br />Your business is 
                          one-of-a-kind, and so should be your website. We excel in crafting bespoke web solutions that align perfectly with your unique needs and objectives. Whether it's a personal blog, 
                          a corporate site, or anything in between, our custom website development expertise brings your vision to life.<br /><br />Let us bridge the gap between your web vision and reality. 
                          Whether you're starting from scratch or looking to revamp an existing site, DGi WORX has the knowledge and creativity to turn your digital dreams into a powerful online 
                          presence.</p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>



          </div>
        </div>
      </section >





      < section className="feature-area py-50" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="tp-section tp-section-white text-center mb-100">
                <span className="tp-section-sub-title">Crafting Excellence</span>
                <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">Our Process Flow</h2>
                <h3 className='section-sub-title col-lg-10 col-sm-12 text-dark mx-auto'>Discover the roadmap to excellence with a glimpse into 'Our Process Flow'. Dive into the
                  structured approach that propels DGi WORX toward delivering outstanding results.</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-80">
                <div className="tp-feature-4-thumb" data-background="">
                </div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i ><BsBrush className="flaticon-product-design" /></i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">Design</h4>
                    <p>Transform your vision into a visual masterpiece as our expert designers craft the blueprint of your digital project.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-80">
                <div className="tp-feature-4-thumb" data-background="">
                </div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i ><BsBracesAsterisk className="flaticon-solution" /></i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">Code</h4>
                    <p>Our skilled developers bring your design to life with meticulous coding, ensuring functionality and responsiveness.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="tp-feature-4-item mb-80">
                <div className="tp-feature-4-thumb" data-background="">
                </div>
                <div className="tp-feature-4-item-wrapper">
                  <div className="tp-feature-4-item-icon">
                    <i ><BsRocketTakeoff className="flaticon-solution" /></i>
                  </div>
                  <div className="tp-feature-4-item-content">
                    <h4 className="tp-feature-4-item-title">Launch</h4>
                    <p>The culmination of our efforts, as we unveil your project to the world, making it accessible to your audience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >



      {/* faq section */}

      < Faq items={faqitem} />

      {/* Faq page end */}


      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div >
  )
}

export default Webdevelopment;